<template>
  <!-- 热点问题 -->
  <div>
    <div class="helper-title">
      常见问题
    </div>
    <div class="hot-issue" v-loading="hotlssueDataLoading">
      <div class="hot-issue-content" v-for="(item, i) in hotlssueData" :key="i" @click="clickHotIssue(item)">
        <div class="hot-issue-content-item">{{ item.title }}</div>
        <div class="hot-issue-content-time">{{ item.updated.split("T")[0] + " " + item.updated.split("T")[1] }}</div>
      </div>
      <div v-if="!hotlssueData.length > 0"  style="text-align: center;padding-top: 120px;font-size: 18px;color: #909399;">
        暂无数据
      </div>
    </div>


    
    <!--分页组件-->
    <el-pagination style="margin: 32px 0 48px 0;text-align: center;" background @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle" :current-page="page.pageIndex" :page-sizes="[10, 20, 50, 100]"
      :page-size="page.pageSize" :total="page.totalPage" layout="prev, pager, next">
    </el-pagination>
  </div>


</template>

<script>
import { httpGet } from "@/utils/httpRequest";

export default {
  name: "HotIssue",
  data() {
    return {
      // 热点问题加载
      hotlssueDataLoading: true,
      //分页对象
      page: {
        //当前页
        pageIndex: 1,
        //每页显示数据
        pageSize: 5,
        //总页数
        totalPage: 0,
      },
      hotlssueData: [],
    }
  },
  methods: {
    // 热点问题详情页跳转
    clickHotIssue(item) {
      // console.log(item);
      this.$router.push(
        {
          name: 'HotIssueDetails',
          query: { data: item }
        });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.page.pageSize = val;
      this.page.pageIndex = 1;
    },
    // 当前页
    currentChangeHandle(val) {
      this.page.pageIndex = val
    },
    getData() {
      httpGet("/news-info/list",
        {
          typeId: 4
        }
        , (res) => {
          this.hotlssueData = res.data.records;
          // console.log(res.data.records)
          // 关闭加载
          this.hotlssueDataLoading = false
        }, (err) => {
          // // console.log(err)
          throw err
        })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.helper-title{
  text-align: left;
  font-size: 26px;
  color: #1e9eff;
  padding: 20px 0;
  border-bottom: 1px solid #d5d5d584;
}
.hot-issue {
  margin-top: 10px;
  padding: 0 10px;
  height: 24rem;
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    color: #FFF;
    width: 66px;
    height: 36px;
    line-height: 36px;
    background: #1371E7;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    border: 1px solid #EDEFF2;
  }

  ::v-deep .el-pagination.is-background .btn-next,
  ::v-deep .el-pagination.is-background .btn-prev,
  ::v-deep .el-pagination.is-background .el-pager li {
    width: 66px;
    height: 36px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    border: 1px solid #EDEFF2;
    border-radius: 2px;
  }

  .hot-issue-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EDEFF2;
    cursor: pointer;

    .hot-issue-content-item {
      height: 4.5625rem;
      display: flex;
      align-items: center;
    }

    .hot-issue-content-item::before {
      content: "";
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      background: url('~@/assets/img/pre/icon-message-1.png');
      background-size: 100% 100%;
      margin-right: 0.75rem;
    }

    .hot-issue-content-time {
      font-size: 1.125rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #667280;
    }
  }
}
</style>